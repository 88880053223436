<template>
  <App
    title="资金单"
    left-arrow
  >
    <Card
      class="list"
      v-for="$e in dataList"
      :key="$e.payFlowNo"
    >
      <template>
        <template slot="title">
          <div class="card-info">
            <h3 class="name">No.{{$e.payFlowNo}}
              <Tag
                v-clipboard:copy="$e.payFlowNo"
                v-clipboard:success="onCopy"
                plain
                round
                @click.stop
              >复制</Tag>
            </h3>
            <span class="tag">{{$e.gatheringItemCode}}</span>
          </div>
        </template>
        <div class="info">
          <div>支付方式：{{$e.payType}}</div>
          <div>收款时间：{{$e.gateringDate}}</div>
          <div>金额：{{$e.factReceivedAmount}}</div>
          <div>单据类型：{{$e.orderType === '0' ? '收款' : '退款'}}</div>
        </div>
      </template>
    </Card>
    <div
      class="noData"
      v-if="noData"
    >暂无数据</div>
  </App>
</template>

<script>
import { Tag, Toast } from 'vant'
import Card from '_c/card'
import dayjs from 'dayjs'
// 资金单收款方式
const payTypeAttr = {
  '00': '支付宝',
  '01': '微信',
  '03': '快捷',
  '04': '电汇'
}
// 资金单项目
const gatheringItemAttr = {
  '5': '意向金',
  '10': '大定补差',
  '15': '定金',
  '20': '首付款',
  '25': '尾款'
}

export default {
  name: 'moneyOrder',
  components: {
    Card,
    Tag
  },
  data () {
    return {
      noData: false,
      dataList: []
    }
  },
  methods: {
    onCopy () {
      Toast('复制成功!')
    }
  },
  mounted () {
    this.orderInfo = this.$route.query || {}
    if (this.orderInfo.payList) {
      this.dataList = this.orderInfo.payList.map(e => Object.assign(e, {
        gatheringItemCode: gatheringItemAttr[e.gatheringItemCode],
        payType: payTypeAttr[e.payType],
        gateringDate: dayjs(e.gateringDate * 1000).format('YYYY-MM-DD HH:mm:ss')
      }))
    }
    this.noData = !(this.orderInfo.payList && this.orderInfo.payList.length)
  }
}
</script>
<style lang="less" scoped>
.list {
  .card-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      width: 500px;
      margin-right: 20px;
    }

    .tag {
      display: inline-block;
      padding: 0 10px;
      color: @blue;
      font-size: 12px;
      height: 42px;
      line-height: 42px;
    }
  }
  .info {
    font-size: 24px;
    color: #666;
    div {
      height: 34px;
    }
  }
}
.noData {
  color: #969799;
  font-size: 14px;
  line-height: 100px;
  text-align: center;
}
</style>
